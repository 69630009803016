import intro from './intro';
import step from './step';
import position from './position';
import tooltipClass from './tooltip-class';
import highlightClass from './highlight-class';
import scrollTo from './scroll-to';
import disableInteraction from './disable-interaction';
import hint from './hint';
import hintPosition from './hint-position';
import autostart from './autostart';
import conditional from './if';

export const DIRECTIVES = {
    intro, step, position, tooltipClass, highlightClass, scrollTo,
    disableInteraction, hint, hintPosition, autostart, conditional
};
